<template>
    <router-view v-if="route?.meta?.hideContent"></router-view>
    <el-row class="app" v-if="!route?.meta?.hideContent">
        <div class="slider">
            <SliderMenu />
        </div>
        <div class="content-right">
            <el-row class="header">
                <HeaderBar />
            </el-row>
            <el-row class="content">
                <router-view></router-view>
            </el-row>
        </div>
    </el-row>
</template>

<script>
import SliderMenu from "@/components/SliderMenu";
import HeaderBar from "@/components/HeaderBar";
import { useRoute } from 'vue-router'

export default {
    name: "App",
    setup() {
      const route = new useRoute()
  console.log('route1', route)
      return {
        route
      }
    },
    components: {
        SliderMenu,
        HeaderBar,
    },
};
</script>

<style lang="scss" scoped>
.header {
    height: 52px;
}

.slider {
    width: 200px;
}

.content-right {
    width: calc(100% - 200px);
}

.content {
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 16px;
    box-sizing: border-box;
    background: #f6f8fc;
}
</style>