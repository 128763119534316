import { createApp } from 'vue'
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
import "@/assets/css/base.css"; /** 引入公共css */
import router from "./router/index";
import App from "./App.vue";

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.use(ElementPlus).use(router)
app.mount('#app')
