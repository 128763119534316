<template>
    <el-row class="slider-menu">
        <el-row class="logo">百应互联</el-row>
        <el-menu
            active-text-color="#fff"
            background-color="#1B222A"
            class="el-menu-vertical"
            text-color="#fff"
            :default-active="route.path"
            router
        >
            <div v-for="(item, index) in menuList" :key="index">
                <el-menu-item :index="item.path" v-if="!item.hideMenu">
                    <el-icon>
                        <component :is="item.icon"></component>
                    </el-icon>
                    <span>{{ item.name }}</span>
                </el-menu-item>
            </div>
        </el-menu>
    </el-row>
</template>

<script setup>
import { useRoute } from "vue-router";
import menuList from "@/router/module";
const route = new useRoute()
</script>

<style lang="scss">
.slider-menu {
    .is-active {
        background: #3572ff;
    }

    .el-menu-item {
        min-width: 0;
        width: 100%;
    }

    .el-menu {
        border: none;
    }
}
</style>

<style lang="scss" scoped>
.slider-menu {
    display: flex;
    flex-direction: column;
    background: #1b222a;

    .logo {
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #fff;
    }
    .el-menu-vertical {
        height: calc(100vh - 52px);
    }
}
</style>