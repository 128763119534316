import {
  createRouter,
  createWebHashHistory
} from "vue-router"
import {
  ElMessage
} from 'element-plus'

import { routes } from './module'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('toothToken')
  if ((to.path == '/login' || to.path == '/') && token) {
    next(from.path)
    return
  }

  if (to.path == '/login') {
    next()
  } else {
    if (!token) {
      ElMessage.error('请先登录')
      next('/login')
    } else {
      if (to.path == '/404') {
        next()
        return
      }
      if (to.path !== '/') {
        next()
      } else {
        next('/404')
      }
    }
  }
})

export default router