<template>
  <el-row class="header-bar">
    <el-row class="header-bar-left">{{ route.name }}</el-row>
    <el-row class="header-bar-right">
      <el-row>
        <img
          :src="userInfo?.adminUser?.headImage || 'https://auok.oss-cn-beijing.aliyuncs.com/ruok/20231025/062751ed96f409e0006e24ca162ea4df.png'"
        />
        <div class="name">{{userInfo?.adminUser?.userName}}</div>

        <div class="logout">
          <el-button @click="handleLogout">退出登录</el-button>
        </div>
      </el-row>
    </el-row>
  </el-row>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
import { userLogout } from "@/services/modules/user";
import { ElMessage } from "element-plus";
const route = new useRoute();
const router = new useRouter();
const userInfo = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : {};

const handleLogout = async () => {
  const res = await userLogout();
  if (res.code === 1) {
    ElMessage.success("退出成功");
    window.localStorage.clear();
    router.push("/login");
    return;
  }

  ElMessage.error(res.msg || res.message || "退出失败");
};
</script>

<style lang="scss" scoped>
.header-bar {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  padding-right: 32px;

  &-left {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #1b1919;
  }

  &-right {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover {
      .logout {
        display: block !important;
      }
    }

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    .name {
      display: flex;
      align-items: center;
      margin-left: 11px;
      color: #292929;
      font-size: 14px;
    }

    .logout {
      position: absolute;
      bottom: -40px;
      z-index: 9;
      left: 0;
      display: none;
    }
  }
}
</style>