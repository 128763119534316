export const routes = [
  {
    hideMenu: true,
    path: '/',
    redirect: '/home'
  },
  {
    hideMenu: true,
    meta: {
      hideContent: true,
    },
    path: "/login",
    component: () => import("@/pages/login/index"),
  }, {
    path: '/404',
    component: () => import('@/pages/PageError/index.vue'),
    name: '404',
    icon: '',
    hideMenu: true,
    meta: {
      hideContent: true,
    },
  },
  {
    path: "/home",
    name:'工作台',
    icon: 'Menu',
    component: () => import("@/pages/home/index"),
  },
  {
    path: "/task",
    name:'营销中心',
    icon: 'DataLine',
    component: () => import("@/pages/task/index"),
  },
  {
    path: "/setting",
    name: '通用设置',
    icon: 'Setting',
    component: () => import("@/pages/setting/index"),
  },
];

export default routes;
