import request from '../index'

export async function userLogin(params) {
  const res = await request('/admin/login', params, {
    method: 'POST'
  })
  return res
}

export async function userLogout(params) {
  const res = await request('/admin/logout', params, {
    method: 'POST'
  })
  return res
}

export async function getUserInfo() {
  const res = await request('/admin/user/info')
  return res
}