import axios from "axios";
let baseUrl = "http://api.byhulian.com/manage";
const request = async (url, data = {}, config = {}) => {
  const tokenParams = window.localStorage.getItem("toothToken")
    ? {
      "third-admin-token": window.localStorage.getItem("toothToken"),
    }
    : {};
  const _header = {
    ...tokenParams,
    ...(config.headers || {}),
  };

  const _data =
    typeof data === "string"
      ? data
      : {
        ...data,
      };

  let requestUrl = baseUrl + url;
  return new Promise((resolve, reject) => {
    let dataParams =
      config.method == "POST" || config.method == "PUT"
        ? {
          data: _data,
        }
        : {
          params: _data,
        };
    axios({
      url: requestUrl,
      ...dataParams,
      method: config.method || "GET",
      headers: _header,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject()
        console.log(error, "接口错误信息");
      });
  });
};

export default request;
